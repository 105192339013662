import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Navbar from './sections/Navbar';
import Footer from './sections/Footer';
import ContactUsButton from './elements/contactUsButton';
import Seo from './Seo';

const Layout = function Layout({ children }) {
  const [showGetInTouch, setShowGetInTouch] = useState(false);

  /* eslint-disable no-undef */
  const handleScroll = () => {
    if (window.pageYOffset > 700) {
      if (!showGetInTouch) setShowGetInTouch(true);
    } else if (showGetInTouch) setShowGetInTouch(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <>
      <Seo />
      <header>
        <Navbar />
      </header>
      <main className="main-page-content">
        {showGetInTouch
        && <ContactUsButton />}
        { children }
      </main>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};
