import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Logo from '../../assets/images/NuJob_Logo-White.svg';
import MobileBars from '../../assets/images/mobile-menu.svg';
import { siteContext } from '../../context/provider';

const NavLink = function NavLink(props) {
  const { to } = props;
  return (
    <Link
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
      getProps={(linkProps) => {
        const isCurrent = to.substring(1) === linkProps.location.hash;
        return {
          style: {
            color: isCurrent ? '#DD9F42' : 'white',
          },
        };
      }}
    />
  );
};

const Navbar = function Navbar() {
  const { handleChange } = useContext(siteContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="container">
      <div className="navbar-container">
        <Link to="/" className="logo-link">
          <img src={Logo} alt="NuJob Logo" className="navbar-logo" />
        </Link>
        <div
          className={`mobile-menu-button ${isOpen && 'rotate-menu-button'}`}
          role="button"
          tabIndex={0}
          onClick={() => setIsOpen(!isOpen)}
          onKeyDown={() => setIsOpen(!isOpen)}
        >
          <img src={MobileBars} alt="Mobile Menu Button" />
        </div>
        <div className="navbar-link-container">
          <NavLink to="/#our-approach" onClick={() => handleChange(true)} className="navbar-links" activeClassName="navbar-active">Hire Talent</NavLink>
          <NavLink to="/#our-support" onClick={() => handleChange(false)} className="navbar-links" activeClassName="navbar-active">Find Opportunities</NavLink>
          <NavLink to="/#about-us" className="navbar-links" activeClassName="navbar-active">About Us</NavLink>
          <Link to="/resources" className="navbar-links" activeClassName="navbar-active">Resources</Link>
          <NavLink to="/#contact" className="navbar-links" activeClassName="navbar-active">Contact</NavLink>
        </div>
      </div>
      {isOpen
      && (
        <div className="mobile-menu">
          <Link to="/" className="mobile-menu-navbar-links" activeClassName="mobile-active" onClick={() => setIsOpen(false)}>Home</Link>
          <Link
            to="/#our-approach"
            className="mobile-menu-navbar-links"
            activeClassName="mobile-active"
            onClick={() => {
              setIsOpen(false);
              handleChange(true);
            }}
          >
            Hire Talent
          </Link>
          <Link
            to="/#our-support"
            className="mobile-menu-navbar-links"
            activeClassName="mobile-active"
            onClick={() => {
              setIsOpen(false);
              handleChange(false);
            }}
          >
            Find Opportunities
          </Link>
          <Link to="/#about-us" className="mobile-menu-navbar-links" activeClassName="mobile-active" onClick={() => setIsOpen(false)}>About Us</Link>
          <Link to="/resources" className="mobile-menu-navbar-links" activeClassName="mobile-active" onClick={() => setIsOpen(false)}>Resources</Link>
          <Link to="/#contact" className="mobile-menu-navbar-links orange-button" activeClassName="mobile-active" onClick={() => setIsOpen(false)}>Contact</Link>
        </div>
      )}
    </nav>
  );
};

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default Navbar;
