import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Logo from '../../assets/images/NuJob_Logo-White.svg';
import Copyright from '../../assets/images/icons/copyright.png';
import { useFooter } from '../../custom-query-hooks/useFooter';
import { siteContext } from '../../context/provider';

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="richText footer-text">{ children }</p>,
  },
  /* eslint-disable no-shadow */
  renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
};

const Footer = function Footer() {
  const { handleChange } = useContext(siteContext);
  const footer = useFooter();

  return (
    <div className="footer-container">
      <p className="footer-title">
        Welcome to
        <span className="bold"> Nujob Executive Search</span>
      </p>
      <p className="footer-blurb">
        { documentToReactComponents(JSON.parse(footer.text.raw), options)}
      </p>
      <div className="links-container">
        <div className="links">
          <div>
            <p className="bold footer-link">
              Company Info
            </p>
            <hr className="top-link" />
          </div>
          <Link to="/#about-us"><p className="footer-link">About Us</p></Link>
          <Link to="/#contact"><p className="footer-link">Contact Us</p></Link>
          <Link to="/resources"><p className="footer-link">Resources</p></Link>
        </div>
        <div className="links small-links">
          <div>
            <p className="bold footer-link">
              Quick Links
            </p>
            <hr className="top-link" />
          </div>
          <Link
            to="/#our-approach"
            onClick={() => {
              handleChange(true);
            }}
          >
            <p className="footer-link">Hire Talent</p>
          </Link>
          <Link to="/#our-support" onClick={() => handleChange(false)}><p className="footer-link">Find Opportunities</p></Link>
        </div>
      </div>
      <img className="footer-logo" src={Logo} alt="NuJob Logo" />
      <p className="footer-copyright">
        <img src={Copyright} alt="Copyright symbol" className="footer-copyright-logo" />
        nujob.com
        {' '}
        { new Date().getFullYear() }
      </p>
    </div>
  );
};

export default Footer;
