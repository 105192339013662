import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useSiteMetadata } from '../custom-query-hooks/useSiteMetaData';

const Seo = function Seo({ title, description }) {
  const {
    title: metadataTitle,
    description: metadataDescription,
  } = useSiteMetadata();
  const metaTitle = title ? `${title} | ${metadataTitle}` : metadataTitle;
  const metaDescription = description || metadataDescription;

  return (
    <Helmet htmlAttributes={{ lang: 'en' }} title={metaTitle}>
      <meta name="description" content={metaDescription} />
      {/* <meta name="image" content={metadata.image} /> */}
    </Helmet>
  );
};

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

Seo.defaultProps = {
  title: null,
  description: null,
};

export default Seo;
