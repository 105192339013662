import { graphql, useStaticQuery } from 'gatsby';

/* eslint-disable import/prefer-default-export */
export const useFooter = () => {
  const { allContentfulFooter } = useStaticQuery(
    graphql`
      {
        allContentfulFooter {
          nodes {
            title
            text {
              raw
            }
          }
        }
      }
    `,
  );
  return allContentfulFooter.nodes[0];
};
