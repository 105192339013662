import React from 'react';
import { Link } from 'gatsby';

const ContactUsButton = function ContactUsButton() {
  return (
    <Link
      to="/#contact"
      className="contact-button"
      role="button"
    >
      <span>Contact Us</span>
    </Link>
  );
};

export default ContactUsButton;
